import React from "react"
import LoginLayout from '../layouts/LoginLayout'
import Forgot from '../components/Forgot'

const ForgotPage = () => {
  const redirect = "/login"
  
  return (
    <LoginLayout title="Gestión B - Olvide mi clave">
        <Forgot redirect={redirect} />
    </LoginLayout>
  )
}

export default ForgotPage