import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate, Link } from 'gatsby'
import { forgot, unsetError } from '../store/actions/useAuth'
import { Spinner  } from 'react-bootstrap';
// import './login.css'

const Forgot = ({ redirect }) => {
  const dispatch = useDispatch()

  const userCargando = useSelector( state => state.auth.loading );
  const loggedIn = useSelector( state => state.auth.loggedIn );
  
  const [email, setEmail] = useState('')
  const [enviado, setEnviado] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (event) => {
    setEnviado(false)
    setError(null)
    event.preventDefault()
    if ( email === "" ){
      setError("Debe ingresar usuario/email de la cuenta.")
      return false;
    }
    try{
      setEnviado(true)
      
      const retForgot = await dispatch( forgot({ email }) )
      console.log("forgot", retForgot)
      if(retForgot.status){
        navigate(redirect)
      }
      else{
        setError(retForgot.msg);
      }
      
    }catch(e){
      console.error(e)
    }
  }

  useEffect(() => {
    if(loggedIn){
      navigate("/app/dashboard")
    }
  }, [])

  return (
    (loggedIn)?
    ""
    :
    <div className="wrapper fadeInDown">
        <div id="formContent">
            {/* <!-- Icon --> */}
            <div className="fadeIn first p-3">
            <h5>Recuperar Pasword</h5>
            </div>

            <form onSubmit={handleSubmit}>
            
              <label className="text-uppercase mb-2">Usuario</label>
              <input
                  className="form-control fadeIn second mb-3"
                  onChange={ e => {
                    setEmail(e.target.value)
                  }}
                  value={ email }
                  disabled={userCargando}
                  id="username" type="email" placeholder="Ingresar Email de recuperación"/>
              <button type="submit" className="btn mb-2" disabled={userCargando}>Recuperar Password</button>
            </form>

            {/* <!-- Remind Passowrd --> */}
            <div id="formFooter mb-1">
                { (error && !error.status && error.length > 1) && (
                    <p className="alert alert-danger mb-2">
                    { error }
                    </p>
                )}
                { (error && error.status && enviado) && (
                    <p className="alert alert-success mb-2">
                    Se han enviado las indicaciones al correo ingresado.
                    </p>
                )}
            </div>
            {(userCargando)?
            <div className="p-2"><Spinner animation="border" variant="info" /></div>
              
            :
            ""
            }
            <Link className="font-weight-bold" to={redirect}>Volver al login</Link>

        </div>
    </div>
  )
}

export default Forgot